<template>
  <div class="table-responsive-sm">
    <table class="table b-table table-fixed">
      <thead>
        <tr>
          <th>#</th>
          <th>User</th>
          <th v-if="!hide_type">Type</th>
          <th v-if="!hide_bg">Bank Guarantee</th>
          <th>Amount</th>
          <th>Time &amp; Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td colspan="7" class="text-center">
            <b-spinner
              class="mb-1"
              variant="primary"
            /><br>
            Loading...
          </td>
        </tr>
        <tr v-else v-for="item in result.data" v-bind:key="item.id">
          <td>{{ item.id }}</td>
          <td v-if="item.user != null">
            <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                {{ item.user.code }}<br><small>{{ item.user.email }}</small>
            </router-link>
          </td><td v-else>-</td>
          <td v-if="!hide_type">{{ item.type }}</td>
          <td v-if="!hide_bg">
            <span v-if="item.is_collateral">
              <feather-icon class="text-success" icon="CheckSquareIcon" />
              <strong v-if="item.collateral_type != null"><br>{{ item.collateral_type.name }}</strong> 
                <span v-if="item.collateral_type != null && item.collateral_value_formatted != null"> ({{ item.collateral_value_formatted }})</span>
              <small v-if="item.active_until != null"><br> Active Until:<br> {{ item.active_until }}</small>
              <br>
              <a v-bind:href="item.file_attachment_url" target="_blank">
                Open File
                <feather-icon
                  icon="ExternalLinkIcon"
                />
              </a>
              <span v-if="item.collateral_status != null && item.collateral_status == 'expired'" :class="'badge badge-danger'">
                Expired
              </span>
              <span v-if="item.collateral_status != null && (item.collateral_status.includes('expire in') || item.collateral_status.includes('expire tomorrow'))" :class="'badge badge-warning text-capitalize'">
                {{ item.collateral_status }}
              </span>
            </span>
          </td>
          <td width="20%">
            <span v-bind:class="item.amount > 0 ? 'text-success' : 'text-danger'">{{ item.amount_formatted }}</span>
          </td>
          <td><small>{{ item.time }}<br>{{ item.note ? item.note : '-' }}</small></td>
          <td>
            <button v-if="!hide_bg && item.is_collateral && checkPermission('edit bank guarantee expire date')" class="btn btn-info btn-sm" @click="editTransaction(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Edit Transaction">
              Edit
            </button>
          </td>
        </tr>
        <tr v-if="result.total == 0 && !isLoading">
          <td colspan="7" class="text-center">Data is empty.</td>
        </tr>
      </tbody>
    </table>

    <div class="m-1" v-if="result.total > 0">
      <div class="row">
        <div class="col">
          <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
        </div>
        <div class="col">
          <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop id="edit-modal" title="Edit Transaction">
      <div class="form">

        <div class="form-group" v-if="formPayload.is_collateral">
            <label for="">Active Until</label><br>
              <flat-pickr
                v-model="formPayload.active_until"
                class="form-control"
                placeholder="Select date"
                :config="{ dateFormat: 'Y-m-d', static: true}"
              />
          </div>

        <div class="form-group" v-if="formPayload.is_collateral">
          <label for="">Expire Alert in</label><br>
          <div class="input-group">
            <input type="number" class="form-control" v-model="formPayload.expire_alert_day" placeholder="3">
            <div class="input-group-append">
              <span class="input-group-text">days before expire</span>
            </div>
          </div>
        </div>

        <div class="form-group" v-if="formPayload.is_collateral">
          <label for="">File Attachment</label><br>
          <input type="file" id="file" ref="file" @change="changeFile()">
          <div v-if="formPayload.file_attachment_url != null">
            <br>
            <a v-bind:href="formPayload.file_attachment_url" target="_blank">Current File</a>
          </div>
        </div>

      </div>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button variant="success" @click="updateTransaction()" v-bind:disabled="isLoading">
          Submit
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BSpinner, BButton } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
      BSpinner,
      BButton,
      flatPickr,
    },
    props: {
      // 'user_id',
      // 'expire_soon',
      user_id: {
        default: null,
        type: Number
      },
      currency_code: {
        default: '',
        type: String
      },
      expire_soon: {
        default: 0,
        type: Number
      },
      is_adjustment: {
        default: null,
        type: Number
      },
      hide_bg: {
        default: false,
        type: Boolean
      },
      hide_type: {
        default: false,
        type: Boolean
      },
    },
    setup(props) {
      return {
        checkPermission,
        successNotification,
        errorNotification,
      }
    },
    data() {
      return {
        currentPage: 1,
        result: {},
        queryParams: {
            page: null,
            user_id: '',
            currency_code: '',
        },
        isLoading: false,
        formPayload: {
          transaction_id: null,
          active_until: '',
          is_collateral: false,
          file_attachment: null,
          file_attachment_url: null,
        },
      }
    },
    watch: { 
      	user_id: function(newVal, oldVal) {
            this.getData(this.currentPage);
        },
        currency_code: function(newVal, oldVal) {
            this.getData(this.currentPage);
        }
    },
    created() {
        this.getData();
    },
    mounted() {
      // var context = this
      // this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
      //   if (payload.type == 'cash-transaction') {
      //     context.getData(context.queryParams.page || 1, false);  
      //   }
      // });
    },
    methods: {
      changeFile() {
        this.formPayload.file_attachment = this.$refs.file.files[0];
      },
      editTransaction(item) {
        this.formPayload.transaction_id = item.id;
        this.formPayload.active_until = item.active_until;
        this.formPayload.is_collateral = item.is_collateral;
        this.formPayload.expire_alert_day = item.expire_alert_day;
        this.formPayload.file_attachment_url = item.file_attachment_url;

        this.$bvModal.show('edit-modal')
      },
      updateTransaction() {
        this.isLoading = true;

        var form = new FormData();
        for (var key in this.formPayload) {
          if (this.formPayload.hasOwnProperty(key) &&  this.formPayload[key] != null) {
            form.append(key, this.formPayload[key]);
          }
        }

        this.$http.post('/admin/cash/edit-transactions', form, {
          headers: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {
  
          this.getData(
            this.currentPage
          )
          this.isLoading = false;
          this.$bvModal.hide('edit-modal')
          successNotification(this, 'Success', 'Transaction successfully updated')
  
        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)   
          }
          this.isLoading = false;
        })
      },
      getData(page = 1, cache = true) {

        this.isLoading = true;
        this.currentPage = page
        this.queryParams.user_id = this.user_id
        // added by bani
        this.queryParams.currency_code = this.currency_code
        this.queryParams.page = page
        this.queryParams.expire_soon = this.expire_soon ? 1 : 0
        this.queryParams.is_adjustment = this.is_adjustment


        this.$http.get(`/admin/cash/transaction-histories?currency_code=${this.currency_code}`, {
            params: this.queryParams,
            useCache: cache
        })
        .then(response => {
            this.result = response.data.data
            this.isLoading = false;
        })

      },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>