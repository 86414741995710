<template>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Commodity &amp; Depository</th>
            <th>Amount</th>
            <th>Time &amp; Description</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="5" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.id">
            <td>{{ item.id }}</td>
            <td v-if="item.user != null">
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.name }}<br><small>{{ item.user.email }}</small>
                </router-link>
              </td><td v-else>-</td>
            <td width="25%">
              {{ item.commodity.name }}<br>
              <small>{{ item.warehouse.name }} - {{ item.warehouse.location }}</small>
            </td>
            <td width="15%" v-bind:class="item.amount > 0 ? 'text-success' : 'text-danger'">{{ item.amount > 0 ? '+' : '-' }} {{ item.amount_formatted }}</td>
            <td><small>{{ item.created_at }}<br>{{ item.note ? item.note : '-' }}</small></td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="5" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
    props: [
      'user_id', 
      'commodity_slug', 
      'warehouse_slug'
    ],
    components: {
      BSpinner,
    },
    data() {
      return {
        currentPage: 1,
        result: {},
        queryParams: {
            page: null,
            user_id: '',
            commodity_slug: '',
            warehouse_slug: '',
        },
        isLoading: false,
      }
    },
    mounted() {
      var context = this
      this.$echo.channel('DashboardAdminUpdate').listen('.DashboardAdminUpdate', (payload) => {
        if (payload.type == 'auction-transaction') {
          context.getData(context.queryParams.page || 1);  
        }
      });
    },
    watch: { 
      	user_id: function() {
          this.getData(this.currentPage);
        },
        commodity_slug: function() {
          this.getData(this.currentPage);
        },
        warehouse_slug: function() {
          this.getData(this.currentPage);
        },
    },
    created() {
        this.getData();
    },
    methods: {
        getData(page = 1) {

        this.isLoading = true;
        this.queryParams.user_id = this.user_id
        this.queryParams.commodity_slug = this.commodity_slug
        this.queryParams.warehouse_slug = this.warehouse_slug
        this.queryParams.page = page

        this.$http.get('/admin/commodity-transactions', {
            params: this.queryParams
        })
        .then(response => {
            this.result = response.data.data
            this.isLoading = false;
        })

        },
    }
}
</script>